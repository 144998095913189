import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, withKeys as _withKeys } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_m_input = _resolveComponent("m-input")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_m_select = _resolveComponent("m-select")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    "default-href": "/tabs/home/settings",
                    text: _ctx.$t('Cancel')
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "primary" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _withModifiers(_ctx.onSubmitForm, ["prevent"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "end",
                        icon: _ctx.checkmark
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('Save')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.itemId ? _ctx.$t('Edit Budget Item') : _ctx.$t('New Budget Item')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode("form", {
            action: "#",
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onSubmitForm()), ["prevent"])),
            onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.onSubmitForm()), ["enter"]))
          }, [
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Title'),
                  name: "budget_name"
                }, null, 8, ["label"]),
                _createVNode(_component_m_select, {
                  label: _ctx.$t('Category'),
                  name: "budget_group_id"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.store.state.budgetGroups, (grp) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: grp.id,
                        value: grp.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(grp.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Price'),
                  name: "amount",
                  type: "number",
                  inputmode: "decimal"
                }, null, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Quantity'),
                  name: "quantity",
                  type: "number",
                  inputmode: "numeric"
                }, null, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Deposit Paid'),
                  name: "deposit",
                  type: "number",
                  inputmode: "decimal"
                }, null, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Payments Made'),
                  name: "paid",
                  type: "number",
                  inputmode: "decimal"
                }, null, 8, ["label"]),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('Balance')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_note, { slot: "end" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.balance), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_radio_group, {
                  modelValue: _ctx.importanceValue,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.importanceValue = $event)),
                  name: "importance"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_list_header, { style: {"--color":"var(--ion-color-text)","font-size":"medium"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('Type of item')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('Essential')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_radio, {
                          slot: "start",
                          value: "0"
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('Wish List')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_radio, {
                          slot: "start",
                          value: "1"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ], 32)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}