
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonNote,
  IonSelectOption,
  IonRadio,
  IonRadioGroup,

  onIonViewDidEnter
 } from '@ionic/vue';

import { checkmark } from 'ionicons/icons';

import { computed, defineComponent, ref } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router';
import { isApiError, BudgetParams } from '@/lib/api-endpoints';
import MInput from '@/components/MInput.vue';
import MSelect from '@/components/MSelect.vue';
import { useStore } from '@/store';
import * as yup from 'yup';
import t, {curr} from '@/translation';
import { useField, useForm } from 'vee-validate';
import budgetIcons from '@/lib/budget_icons';


export default  defineComponent({
  name: 'WeddingInfo',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
    IonList,
    IonListHeader,
    //IonGrid,
    //IonRow,
    //IonCol,
    IonItem,
    IonLabel,
    IonNote,
    //IonDatetime,
    IonSelectOption,
    IonRadio,
    IonRadioGroup,

    MInput,
    MSelect,
    //MPassword,
  },
  setup(_props){

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    function transformEmptyStringZero(value: number, originalValue: string){
      if (originalValue == "") {
        return 0;
      }
      return value;
    }
    
    const itemSchema = yup.object({
      // eslint-disable-next-line @typescript-eslint/camelcase
      budget_name: yup.string().required().label(t("Title")),
      // eslint-disable-next-line @typescript-eslint/camelcase
      budget_group_id: yup.number().required().label(t("Category")),
      amount: yup.number().required().label(t("Price")),
      importance: yup.string().required().label(t("Type of item")),
      paid: yup.number().default(0.00).label(t("Paid")).transform(transformEmptyStringZero),
      deposit: yup.number().default(0.00).label(t("Deposit")).transform(transformEmptyStringZero),
      quantity: yup.number().required().label(t("Quantity")),
    });

    // Create a form context with the validation schema
    const { values, validate, resetForm } = useForm({
      validationSchema: itemSchema,
    });
    
    // create a field for the icon because it does not use a prebuilt component
    const { errorMessage: importanceErrorMessage, value: importanceValue} = useField ("importance");

    const groupId = ref<number | undefined>(undefined);
    const itemId = ref<number | undefined>(undefined);


    // Load up the latest user details when entering this page
    onIonViewDidEnter(async () => {
      resetForm();
      // this page can be entered in one of three ways:
      // 1) with itemId only -> update item
      // 2) with itemId (containing a groupId) and importance -> create new item with specified group and importance values
      // 3) with no args -> create new item only.
      if ( route.params.budgetItemId && route.params.importance ){
        groupId.value = parseInt(route.params.budgetItemId as string);
        itemId.value = undefined;

        values.importance = route.params.importance as string;
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.budget_group_id = groupId.value;

      } else if ( route.params.budgetItemId && ! route.params.importance ){
        itemId.value = parseInt(route.params.budgetItemId as string);
        groupId.value = undefined;


        if (itemId.value){
        const item = store.state.budgets.find( element => element.id == itemId.value );
          // populate the form with the details of the category to be edited.
          if (item){
            // eslint-disable-next-line @typescript-eslint/camelcase
            values.budget_name = item.budget_name;
            // eslint-disable-next-line @typescript-eslint/camelcase
            values.budget_group_id = item.budget_group.id;
            values.amount = parseFloat(item.amount);
            values.importance = item.importance.toString();
            values.paid = item.paid;
            values.deposit = parseFloat(item.deposit);
            values.quantity = item.quantity;
          }
        }
      } else {
        groupId.value = undefined;
        itemId.value = undefined;
        values.importance = "0";
      } 
     
    });

    async function onSubmitForm(){
      const result = await validate();
      if (result.valid){

        const castValues = itemSchema.cast(values);

        const budgetParams: BudgetParams = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          budget_name: castValues.budget_name!,
          // eslint-disable-next-line @typescript-eslint/camelcase
          budget_group_id: castValues.budget_group_id!,
          amount: castValues.amount!,
          importance: parseInt(castValues.importance!),
          paid: castValues.paid!,
          deposit: castValues.deposit!,
          quantity: castValues.quantity!,
          // eslint-disable-next-line @typescript-eslint/camelcase
          wedding_id: store.getters.wedding.id,
          id: itemId.value,
        }

        const budgetItem = await store.dispatch('saveBudgetItem', budgetParams);

        if (! isApiError(budgetItem)){
          router.go(-1); // go back to the last page.
          window.setTimeout(resetForm, 2000); // delayed so that validation errors are not seen before the page transistions.
        }
      } 
    }

    const balance = computed(() => {
      let result = "?"
      if (values.amount != null && values.quantity != null){
        result = curr(values.amount * values.quantity - (values.deposit || 0)  - (values.paid || 0), store.state.userData && store.state.userData.country && store.state.userData.country.code);
      }
      return result;
    });

    return {
      onSubmitForm,
      importanceValue,
      importanceErrorMessage,
      checkmark,
      budgetIcons,
      route,
      store,
      itemId,
      balance,

    }
  }
});
