import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_m_item = _resolveComponent("m-item")!

  return (_openBlock(), _createBlock(_component_m_item, {
    label: _ctx.label,
    "error-message": _ctx.errorMessage
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_input, _mergeProps({
        onChange: _ctx.handleChange,
        onBlur: _ctx.handleChange,
        value: _ctx.modelValue
      }, _ctx.$attrs), null, 16, ["onChange", "onBlur", "value"])
    ]),
    _: 1
  }, 8, ["label", "error-message"]))
}